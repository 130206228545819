import React from "react";

import Section from "../../components/Section";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";
import UploadForm from "../../components/UploadForm";

const SecureUploadPage = () => {
  return (
    <Layout>
    <Background>
      <Hero>
        <MotifColumn>
          <h1>Secure Upload</h1>
          <p>Welcome to the Shift Connect secure upload portal. Please enter your information below and upload the appropriate files by clicking the choose file button.
              Add any additional information you’d like us to know and hit “submit”
              when you’re ready. </p>
        </MotifColumn>
      </Hero>
      <Section>
        <UploadForm />
      </Section>
    </Background>
  </Layout>
);
};

export default SecureUploadPage;
